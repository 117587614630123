import React from "react"
import Layout from "../components/layout"

import UpgradeCheck from "../components/upgrade-check"
import { Seo } from "../components/seo"
import { graphql } from "gatsby"

export const Head = ({ location }) => (
  <Seo title="Upgrade monitor" pathname={location.pathname} />
)

const UpgradeMonitor = ({ data }) => (
  <Layout>
    <h1>Upgrade monitor</h1>
    <UpgradeCheck
      fileData={data.allFile.edges}
      mainnetChains={data.allChainsJson.edges}
      testnetChains={data.allTestChainsJson.edges}
    />
  </Layout>
)

export default UpgradeMonitor

export const query = graphql`
  query {
    allChainsJson {
      edges {
        node {
          id
          logo
          name
          explorerUrl
          services {
            tmVersion
            chainId
          }
        }
      }
    }
    allTestChainsJson {
      edges {
        node {
          id
          logo
          name
          explorerUrl
          services {
            tmVersion
            chainId
          }
        }
      }
    }
    allFile {
      edges {
        node {
          id
          relativePath
          relativeDirectory
          publicURL
        }
      }
    }
  }
`
